.wrpr {
  display: none;
  margin-top: var(--spacing-10);
  padding: var(--spacing-5);
  background-position: right var(--spacing-4);
  background-size: 173px 149px;
  background-repeat: no-repeat;

  & div > p {
    margin-bottom: var(--spacing-3);
    max-width: 66%;
    font-size: 23px;
    line-height: 1.8;
    font-weight: 700;
  }

  & strong {
    font-style: normal;
  }

  &[data-color='mint'] {
    background-color: var(--color-heycar-mint-500);

    & strong {
      background-color: var(--color-primary-500);
      color: #fff;
    }
  }

  &[data-color='mint_light'] {
    background-color: var(--color-heycar-mint-200);

    & strong {
      background-color: var(--color-tertiary-400);
    }
  }

  &[data-color='mica_blue'] {
    background-color: var(--color-primary-500);
    color: #fff;

    & strong {
      background-color: var(--color-secondary-500);
      color: #000;
    }
  }

  &[data-color='sunbeam_blue'] {
    background-color: var(--color-tertiary-500);

    & strong {
      background-color: var(--color-secondary-300);
    }
  }

  @media (--desktop) {
    display: block;
  }
}
