.dealerContentWrapper {
  padding-top: var(--spacing-5);
  gap: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: none;

  & .capitalizeLabel {
    text-transform: capitalize;
  }

  @media (--desktop) {
    padding-top: 0;
    padding-bottom: var(--spacing-10);
    border-bottom: solid 1px var(--color-neutral-300);
  }

  & .dealerHeader {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0;
  }

  & .hideForMobile {
    display: none;
    @media (--tablet) {
      display: block;
      margin: 0;
      width: 752px;
    }
  }

  & .dealerLocationInfo {
    flex-direction: column;
    display: flex;
    gap: 16px;

    @media (--tablet-l) {
      flex-direction: row;
    }

    & .dealerLocation {
      align-items: center;
      gap: 16px;
      display: flex;
    }
  }
}
